<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        类型: <el-select v-model="search.type">
        <el-option :value=-1 label="全部">全部</el-option>
        <el-option :value=0 label="视频">视频</el-option>
        <el-option :value=1 label="线路">线路</el-option>
      </el-select>
      </div>
      <div class="search-box">
        <el-button type="primary" size="medium" @click="showDialog()">添加</el-button>
        <el-button type="primary" size="medium" @click="getList()">刷新</el-button>
<!--        <el-button type="primary" size="medium" @click="getLine()">线路设置</el-button>-->
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="type" label="类型" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          {{getTypeTitle(scope.row)}}
        </template>
      </el-table-column>
      <el-table-column prop="account" label="标题" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="bucket" label="bucket" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="access_key" label="access_key" align="center" :show-overflow-tooltip="true"></el-table-column>
<!--      <el-table-column prop="access_secret" label="access_secret" align="center" :show-overflow-tooltip="true"></el-table-column>-->
      <el-table-column prop="end_point" label="end_point" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="domain" label="domain" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="price" label="余额" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span style="color: #00b12e" v-if="scope.row.price >= 50">{{scope.row.price}}</span>
          <span style="color: #fe2a54" v-if="scope.row.price < 50">{{scope.row.price}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="video_nums" label="长视频数量" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="short_video_nums" label="短视频数量" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template #default="scope">
          <el-switch :active-value=1 @click="editStatus(scope.row)" :value="scope.row.status" :inactive-value=0 ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" @click="showEditDialog(scope.row)">编辑</span>
          <span class="operation" @click="del(scope.row)">移除</span>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加修改 -->
    <el-dialog title="线路设置" v-model="dialogLine" width="600px" top="20vh" :close-on-click-modal="false">
      <el-row justify="start" align="middle">
        <el-col :span="4" >线路</el-col>
        <el-col :span="20">
          <el-input type="textarea" rows="10" resize="none" v-model="lineForm.line" placeholder="请输入线路域名,多个换行隔开!"></el-input>
        </el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4"></el-col>
        <el-col :span="20"><el-button @click="saveLine">保存</el-button></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="24"></el-col>
      </el-row>
    </el-dialog>


    <!-- 添加修改 -->
    <el-dialog title="编辑" v-model="dialogEdit" width="600px" top="20vh" :close-on-click-modal="false">
      <el-row justify="start" align="middle">
        <el-col :span="4" >类型</el-col>
        <el-col :span="20">
          <el-select multiple v-model="editform.types">
            <el-option value="0" label="视频"></el-option>
            <el-option value="1" label="线路"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4" >标题</el-col>
        <el-col :span="20"><el-input v-model="editform.account" placeholder="请输入标题"></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4">Bucket</el-col>
        <el-col :span="20"><el-input v-model="editform.bucket" placeholder="请输入Bucket"></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4">AccessKey</el-col>
        <el-col :span="20"><el-input v-model="editform.access_key" placeholder="请输入AccessKey"></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4">AccessSecret</el-col>
        <el-col :span="20"><el-input v-model="editform.access_secret" placeholder="请输入AccessSecret"></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4">EndPoint</el-col>
        <el-col :span="20"><el-input v-model="editform.end_point" placeholder="请输入EndPoint"></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4">加速域名</el-col>
        <el-col :span="20"><el-input v-model="editform.domain" placeholder="请输入domain"></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4"></el-col>
        <el-col :span="20"><el-button @click="save">修改</el-button></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="24"></el-col>
      </el-row>
    </el-dialog>

    <!-- 添加修改 -->
    <el-dialog :title="dialogTitle" v-model="dialog" width="600px" top="20vh" :close-on-click-modal="false">
      <el-row justify="start" align="middle">
        <el-col :span="4" >类型</el-col>
        <el-col :span="20">
          <el-select multiple v-model="form.types">
            <el-option value="0" label="视频"></el-option>
            <el-option value="1" label="线路"></el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4" >标题</el-col>
        <el-col :span="20"><el-input v-model="form.account" placeholder="请输入标题"></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4">Bucket</el-col>
        <el-col :span="20"><el-input v-model="form.bucket" placeholder="请输入Bucket"></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4">AccessKey</el-col>
        <el-col :span="20"><el-input v-model="form.access_key" placeholder="请输入AccessKey"></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4">AccessSecret</el-col>
        <el-col :span="20"><el-input v-model="form.access_secret" placeholder="请输入AccessSecret"></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4">EndPoint</el-col>
        <el-col :span="20"><el-input v-model="form.end_point" placeholder="请输入EndPoint"></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4">加速域名</el-col>
        <el-col :span="20"><el-input v-model="form.domain" placeholder="请输入domain"></el-input></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="4"></el-col>
        <el-col :span="20"><el-button @click="add">添加</el-button></el-col>
      </el-row>
      <el-row justify="start" align="middle">
        <el-col :span="24"></el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import httpClient from "@/config/httpClient";

export default {
  name: "ossList",
  data() {
    return {
      tableData: [], //数据
      search:{
        type:-1,
      },
      dialogLine:false,
      lineForm:{
        line:"",
      },
      dialog:false,
      dialogEdit:false,
      dialogTitle:"添加",
      editform: {
        account:"",
        types:[],
        type_id:"",
        bucket:"",
        access_key:"",
        access_secret:"",
        end_point:"",
        domain:"",
      },
      form: {
        account:"",
        types:[],
        type_id:"",
        bucket:"",
        access_key:"",
        access_secret:"",
        end_point:"",
        domain:"",
      }, // 表单数据
    };
  },
  mounted() {
    this.getList()
  },
  methods: {
    getTypeTitle(row){
      console.log(row.type)
      if(row.type){
        var title = ""
        for(var i in row.type){
          if (row.type[i] == 0){
            title += "视频,"
          }else if (row.type[i] == 1){
            title += "线路,"
          }
        }
        return title
      }else{
        return "视频"
      }
    },
    getList(){
      httpClient("ossList").post({type:this.search.type}).then((res)=>{
        if(res.code == 0){
          this.tableData = res.data
        }
      })
    },
    editStatus(row){
      var status = row.status == 1?0:1
      httpClient("ossStatus").post({"id":row.id,"status":status}).then((res)=>{
        if(res.code > 0){
          this.$message({message:res.msg,type:"error"})
        }else{
          this.getList()
        }
      })
    },

    showEditDialog(row){
      this.dialogEdit = true
      this.editform = {
        id:row.id,
        types:row.type,
        account:row.account,
        bucket:row.bucket,
        access_key:row.access_key,
        access_secret:row.access_secret,
        end_point:row.end_point,
        domain:row.domain,
      }
    },
    save(){
      this.editform.type_id = this.editform.types.join(",")
      httpClient("ossEdit").post(this.editform).then((res)=>{
        if(res.code == 0){
          this.dialogEdit = false
          this.getList()
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    showDialog(){
      this.dialog = true
      this.form = {
        types:[], // 默认就是0
        type_id:"",
        account:"",
        bucket:"",
        access_key:"",
        access_secret:"",
        end_point:"",
        domain:"",
      }
    },
    add(){
      this.form.type_id = this.form.types.join(",")
      httpClient("ossAdd").post(this.form).then((res)=>{
        if(res.code == 0){
          this.dialog = false
          this.getList()
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      })
    },
    del(row){
      this.$confirm("移除ID: " + row.account + "吗？", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=> {
        httpClient("ossRemove").post({id:row.id,}).then((res)=>{
          if (res.code == 0){
            this.getList()
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        })
      })
    }
  },
};
</script>
